<template>
  <p style="display: flex; align-items: center" v-if="FileKey !== undefined">
    <strong>Download Report:</strong>
    <span
      @click="FileKey === null ? none : downloadReport(FileKey, fileName)"
      style="
        margin-left: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
      "
    >
      {{ FileKey === null ? "-" : `${fileName}` }}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style="margin-left: 10px"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-if="FileKey !== null"
      >
        <path
          d="M20.2502 13.5001C20.4492 13.5001 20.6399 13.5791 20.7806 13.7198C20.9212 13.8604 21.0002 14.0512 21.0002 14.2501V19.5001C21.0002 19.8979 20.8422 20.2794 20.5609 20.5608C20.2796 20.8421 19.8981 21.0001 19.5002 21.0001H4.50024C4.10242 21.0001 3.72089 20.8421 3.43958 20.5607C3.15828 20.2794 3.00024 19.8979 3.00024 19.5001L3.00024 14.2501C3.00024 14.0512 3.07926 13.8604 3.21991 13.7198C3.36057 13.5791 3.55133 13.5001 3.75024 13.5001C3.94916 13.5001 4.13992 13.5791 4.28057 13.7198C4.42123 13.8604 4.50024 14.0512 4.50024 14.2501L4.50024 19.5001H19.5002V14.2501C19.5002 14.0512 19.5793 13.8604 19.7199 13.7198C19.8606 13.5791 20.0513 13.5001 20.2502 13.5001Z"
          fill="#11365a"
        />
        <path
          d="M7.71962 11.0301L11.4696 14.7801C11.5393 14.8499 11.622 14.9052 11.713 14.9429C11.8041 14.9807 11.9017 15.0001 12.0002 15.0001C12.0988 15.0001 12.1964 14.9807 12.2874 14.9429C12.3785 14.9052 12.4612 14.8499 12.5309 14.7801L16.2809 11.0301C16.4216 10.8894 16.5007 10.6985 16.5007 10.4995C16.5007 10.3005 16.4216 10.1096 16.2809 9.96889C16.1401 9.82816 15.9493 9.74909 15.7502 9.74909C15.5512 9.74909 15.3603 9.82816 15.2196 9.96889L12.7502 12.4392V3.74951C12.7502 3.5506 12.6712 3.35983 12.5306 3.21918C12.3899 3.07853 12.1992 2.99951 12.0002 2.99951C11.8013 2.99951 11.6106 3.07853 11.4699 3.21918C11.3293 3.35983 11.2502 3.5506 11.2502 3.74951V12.4392L8.78087 9.96889C8.64014 9.82816 8.44927 9.74909 8.25024 9.74909C8.05122 9.74909 7.86035 9.82816 7.71962 9.96889C7.57889 10.1096 7.49983 10.3005 7.49983 10.4995C7.49983 10.6985 7.57889 10.8894 7.71962 11.0301Z"
          fill="#11365a"
        />
      </svg>
    </span>
  </p>
</template>

<script>
import { DownloadReportPDF } from "@/api/APIs";

export default {
  name: "DownloadReport",
  props: {
    FileKey: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
  },
  methods: {
    async downloadReport(fileKey, fileName) {
      const fileExtension = fileKey.split(".").pop();

      const capitalizedFileName = `${fileName}`
        ?.toLowerCase()
        .replace(/^(.)|\s+(.)/g, function ($1) {
          return $1.toUpperCase();
        });
      let payload = {
        fileKey: fileKey,
      };
      try {
        const response = await DownloadReportPDF(payload);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new File([response.data], `${capitalizedFileName}`, {
            type: `application/${fileExtension}`,
          })
        );
        link.download = `${capitalizedFileName}`;
        document.body.append(link);
        link.click();
        link.remove();
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Oops! Something went wrong, Please try again later",
            {
              type: "error",
            }
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.download-pi-file {
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>
