<template>
  <div
    :class="[
      'table-container',
      {
        'logged-in': !isMobile,
        'not-logged-in': isMobile,
        collapsed: this.$root.isCollapsed && !isMobile,
      },
    ]"
  >
    <div class="frame-35732">
      <FilterComponent
        :searchByColumn="searchByColumn"
        :filters="filters"
        :selectedFilters="selectedFilters"
        :searchQuery="searchQuery"
        :byColumn="byColumn"
        @apply-filters="applyFilters"
        @clear-filters="ClearFilters"
        @handleSearch="handleSearch"
        @refresh="refreshData"
      />
      <div class="tables-wrapper">
        <div class="table-wrapper" v-if="searchResults?.data?.length > 0">
          <!-- Desktop -->
          <table
            id="searchResultTable"
            v-if="!mobile && searchResults?.data?.length > 0"
          >
            <thead style="background: #11365a">
              <tr>
                <th v-for="col in tabelColumns" :key="col">
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(searchResult, index) in searchResults?.data"
                :key="index"
              >
                <tr
                  id="result-wrapper"
                  :style="[
                    {
                      backgroundColor:
                        searchResult?.Repeat_Urgent === 'Repeat-Urgent' ||
                        searchResult?.Repeat_Urgent === 'Urgent'
                          ? '#d6f8cb'
                          : index % 2 === 0
                          ? '#ffffff'
                          : '#ecf4ff',
                    },
                  ]"
                  @click="toggleRow(index)"
                >
                  <td>{{ searchResult?.Internal_order_id }}</td>
                  <td>
                    {{ formatDate(searchResult?.Request_date) }}
                  </td>
                  <td>
                    {{
                      formatDate(
                        searchResult.MD_Maker_DeliveryDate &&
                          searchResult.MD_Maker_DeliveryDate !== ""
                          ? searchResult.MD_Maker_DeliveryDate
                          : searchResult?.Due_date
                      )
                    }}
                  </td>
                  <td>{{ searchResult?.Report_name }}</td>
                  <!-- <td>{{ searchResult?.Country }}</td> -->
                  <td>
                    <div
                      :style="getOrderStatusStyle(searchResult?.Order_status)"
                      class="status-badge"
                    >
                      {{ searchResult?.Order_status }}
                    </div>
                  </td>
                  <td>{{ searchResult?.Bank_name }}</td>
                  <td>
                    {{
                      searchResult?.order_source === null
                        ? "-"
                        : searchResult?.order_source
                    }}
                  </td>
                </tr>
                <tr v-if="selectedRow === index" class="expanded-row">
                  <td colspan="7">
                    <div class="expanded-content">
                      <p>
                        <strong>Other Company Name:</strong>
                        <input
                          type="text"
                          class="company-id"
                          placeholder="Enter Company Name"
                          style="margin: 0 0 0 15px; width: 50%"
                          v-model="orderDetails.otherCompanyName"
                        />
                      </p>
                      <p v-if="!orderDetails?.otherAddresses?.company_address">
                        <strong>Company Address:</strong>
                        {{ searchResult.Company_Address }}
                      </p>
                      <div
                        style="
                          width: 100%;
                          display: flex;
                          align-items: flex-start;
                          margin: 5px 0;
                        "
                        v-if="orderDetails?.otherAddresses?.company_address"
                      >
                        <strong>Company Address:</strong>
                        <textarea
                          class="maker-remark"
                          placeholder="Enter Company Address"
                          v-model.trim="
                            orderDetails.otherAddresses.company_address.value
                          "
                          style="flex-grow: 1; resize: none; height: 40px"
                        ></textarea>
                        <div
                          class="three-way-toggle switch"
                          :class="
                            getToggleClass(
                              orderDetails?.otherAddresses?.company_address
                                ?.isVerified
                            )
                          "
                          @click="cycleVerification('company_address')"
                        ></div>
                        <span
                          style="
                            margin-left: 5px;
                            min-width: 115px;
                            margin-top: 10px;
                          "
                        >
                          {{
                            getToggleLabel(
                              orderDetails?.otherAddresses?.company_address
                                ?.isVerified
                            )
                          }}
                        </span>
                      </div>
                      <div
                        style="
                          width: 100%;
                          display: flex;
                          align-items: flex-start;
                          margin: 5px 0;
                        "
                        v-if="
                          orderDetails?.otherAddresses?.registered_addresses
                        "
                      >
                        <strong>Registered Address:</strong>
                        <textarea
                          class="maker-remark"
                          placeholder="Enter Registered Address"
                          v-model.trim="
                            orderDetails.otherAddresses.registered_addresses
                              .value
                          "
                          style="flex-grow: 1; resize: none; height: 40px"
                        ></textarea>
                        <div
                          class="three-way-toggle switch"
                          :class="
                            getToggleClass(
                              orderDetails?.otherAddresses?.registered_addresses
                                ?.isVerified
                            )
                          "
                          @click="cycleVerification('registered_addresses')"
                        ></div>
                        <span
                          style="
                            margin-left: 5px;
                            min-width: 115px;
                            margin-top: 10px;
                          "
                        >
                          {{
                            getToggleLabel(
                              orderDetails.otherAddresses.registered_addresses
                                .isVerified
                            )
                          }}
                        </span>
                      </div>
                      <div
                        style="
                          width: 100%;
                          display: flex;
                          align-items: flex-start;
                          margin: 5px 0;
                        "
                        v-if="orderDetails?.otherAddresses?.address_by_customer"
                      >
                        <strong>Address by Customer:</strong>
                        <textarea
                          class="maker-remark"
                          placeholder="Enter Company Address Provided By Customer"
                          v-model.trim="
                            orderDetails.otherAddresses.address_by_customer
                              .value
                          "
                          style="flex-grow: 1; resize: none; height: 40px"
                        ></textarea>
                        <div
                          class="three-way-toggle switch"
                          :class="
                            getToggleClass(
                              orderDetails?.otherAddresses?.address_by_customer
                                ?.isVerified
                            )
                          "
                          @click="cycleVerification('address_by_customer')"
                        ></div>
                        <span
                          style="
                            margin-left: 5px;
                            min-width: 115px;
                            margin-top: 10px;
                          "
                        >
                          {{
                            getToggleLabel(
                              orderDetails.otherAddresses.address_by_customer
                                .isVerified
                            )
                          }}
                        </span>
                      </div>
                      <div
                        style="
                          width: 60%;
                          display: flex;
                          align-items: flex-start;
                          flex-direction: column;
                        "
                        v-if="
                          orderDetails?.otherAddresses?.more_addresses?.length >
                          0
                        "
                      >
                        <strong>More Addresses:</strong>
                        <div
                          v-for="(address, index) in orderDetails.otherAddresses
                            .more_addresses"
                          :key="index"
                          style="width: 100%; display: flex; margin-top: 5px"
                        >
                          <textarea
                            class="maker-remark"
                            placeholder="Enter Company Address"
                            v-model.trim="
                              orderDetails.otherAddresses.more_addresses[index]
                            "
                            style="flex-grow: 1; resize: none; height: 40px"
                          ></textarea>
                        </div>
                      </div>
                      <p>
                        <strong>More Information: </strong>
                        {{ orderDetails?.moreInformation || "-" }}
                      </p>
                      <p style="width: 66%; display: inline-block">
                        <strong>Line of Business: </strong>
                        <input
                          type="text"
                          class="company-id"
                          placeholder="Enter Line of Business"
                          style="margin: 0 0 0 15px; width: 70%"
                          v-model="orderDetails.lineOfBusiness"
                        />
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Risk Score: </strong>
                        <select
                          v-model="orderDetails.riskScore"
                          :style="{
                            marginLeft: '15px',
                            color:
                              orderDetails.riskScore === null
                                ? '#757575'
                                : '#11365A',
                          }"
                        >
                          <option value="null" disabled>Please select</option>
                          <option
                            v-for="(value, index) in riskScoreList"
                            :key="index"
                            :value="value"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </p>
                      <p
                        style="
                          width: 33%;
                          display: inline-flex;
                          align-items: flex-start;
                          padding-right: 10px;
                        "
                      >
                        <strong>Report Type:</strong>
                        {{ orderDetails?.reportType || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Order Contact Number: </strong>
                        {{ orderDetails?.orderContactNumber || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Company Id:</strong>
                        <input
                          type="text"
                          class="company-id"
                          placeholder="Enter Company Id"
                          style="margin: 0 0 0 15px"
                          v-model="searchResult.Company_Id"
                        />
                      </p>
                      <p style="width: 66%; display: inline-block">
                        <strong>Order Status:</strong>
                        <select
                          v-model="searchResult.Order_status"
                          :style="{
                            marginLeft: '15px',
                            color:
                              searchResult.Order_status === null
                                ? '#757575'
                                : '#11365A',
                          }"
                        >
                          <option
                            :value="
                              !orderStatus.includes(searchResult.Order_status)
                                ? searchResult.Order_status
                                : null
                            "
                            disabled
                          >
                            {{
                              !orderStatus.includes(searchResult.Order_status)
                                ? `${searchResult.Order_status}`
                                : "Select Order Status"
                            }}
                          </option>
                          <option
                            v-for="(status, index) in orderStatus"
                            :key="index"
                            :value="status"
                          >
                            {{ status }}
                          </option>
                        </select>
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Report System Id:</strong>
                        <input
                          type="text"
                          class="company-id"
                          placeholder="Enter Order Id"
                          style="margin: 0 0 0 15px"
                          v-model="searchResult.Reference_Order_Id"
                        />
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>MD_Maker_DeliveryDate:</strong>
                        <input
                          type="date"
                          v-model="formattedSelectedDueDate"
                          :max="formattedDueDate(searchResult?.Due_date)"
                          style="margin-left: 15px"
                        />
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Urgent/ Repeat:</strong>
                        <select
                          v-model="searchResult.Repeat_Urgent"
                          :style="{
                            marginLeft: '15px',
                            color:
                              searchResult.Repeat_Urgent === null
                                ? '#757575'
                                : '#11365A',
                          }"
                        >
                          <option value="null" disabled>Please select</option>
                          <option key="Repeat" value="Repeat">Repeat</option>
                          <option key="Urgent" value="Urgent">Urgent</option>
                          <option key="Repeat-Urgent" value="Repeat-Urgent">
                            Repeat-Urgent
                          </option>
                        </select>
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Maker Status:</strong>
                        <select
                          v-model="searchResult.Status_from_Handler"
                          :style="{
                            marginLeft: '15px',
                            color:
                              searchResult.Status_from_Handler === null
                                ? '#757575'
                                : '#11365A',
                          }"
                        >
                          <option value="null" disabled>
                            Add Maker Status
                          </option>
                          <option
                            v-for="(status, index) in makerStatus"
                            :key="index"
                            :value="status"
                          >
                            {{ status }}
                          </option>
                        </select>
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Trade Data Shared:</strong>
                        <select
                          v-model="searchResult.Trade_date_shared_status"
                          :style="{
                            marginLeft: '15px',
                            color:
                              searchResult.Trade_date_shared_status === null
                                ? '#757575'
                                : '#11365A',
                          }"
                        >
                          <option value="null" disabled>Please select</option>
                          <option
                            v-for="(value, index) in tradeData"
                            :key="index"
                            :value="value"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Financials Updated (Company):</strong>
                        <select
                          v-model="searchResult.Financial_Updated_Company"
                          :style="{
                            marginLeft: '15px',
                            color:
                              searchResult.Financial_Updated_Company === null
                                ? '#757575'
                                : '#11365A',
                          }"
                        >
                          <option value="null" disabled>Please select</option>
                          <option
                            v-for="(value, index) in tradeData"
                            :key="index"
                            :value="value"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Financials Updated (Parent):</strong>
                        <select
                          v-model="searchResult.Financial_Updated_Parent"
                          :style="{
                            marginLeft: '15px',
                            color:
                              searchResult.Financial_Updated_Parent === null
                                ? '#757575'
                                : '#11365A',
                          }"
                        >
                          <option value="null" disabled>Please select</option>
                          <option
                            v-for="(value, index) in tradeData"
                            :key="index"
                            :value="value"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </p>
                      <p
                        style="
                          width: 100%;
                          display: flex;
                          align-items: flex-start;
                        "
                      >
                        <strong>Remarks:</strong>
                        <textarea
                          class="maker-remark"
                          placeholder="Add Remarks"
                          v-model.trim="searchResult.IMP_Remarks"
                          style="
                            flex-grow: 1;
                            resize: none;
                            height: 50px;
                            margin-right: 15px;
                          "
                        ></textarea>
                      </p>
                      <CopyButton :fileName="orderDetails?.referenceNumber" />
                      <p style="width: 33%; display: inline-block">
                        <strong>Original Order Date: </strong>
                        {{ formatDate(orderDetails?.originalOrderDate) || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Action Needed:</strong>
                        {{ searchResult.Action_needed || "-" }}
                      </p>
                      <ReportFileName
                        :fileName="searchResult.report_file_name"
                      />
                      <DownloadPIFile
                        :piFileKey="searchResult.pi_file_key"
                        :internalOrderId="searchResult.Internal_order_id"
                      />
                      <p style="width: 33%; display: inline-block">
                        <strong>Allocated to:</strong>
                        <select
                          v-model="searchResult.Allocated_to"
                          style="margin-left: 15px"
                        >
                          <option value="null" disabled>Select a maker</option>
                          <option
                            v-for="maker in searchResults?.makerList"
                            :key="maker.id"
                            :value="maker.person_name"
                          >
                            {{ maker.person_name }}
                          </option>
                        </select>
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Checker:</strong>
                        <select
                          v-model="searchResult.Checker_name"
                          style="margin-left: 15px"
                        >
                          <option value="null" disabled>
                            Select a checker
                          </option>
                          <option
                            v-for="maker in searchResults?.checkerList"
                            :key="maker.id"
                            :value="maker.person_name"
                          >
                            {{ maker.person_name }}
                          </option>
                        </select>
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Order Email: </strong>
                        {{ orderDetails?.orderEmail || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Client Name: </strong>
                        {{ orderDetails?.misClientName || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Client country: </strong>
                        {{ orderDetails?.misClientCountry || "-" }}
                      </p>
                      <p style="width: 33%; display: inline-block">
                        <strong>Hashed Order Id: </strong>
                        {{ orderDetails?.hashedOrderId || "-" }}
                      </p>
                      <div class="buttons-wrapper" v-if="changesMade">
                        <button
                          type="submit"
                          class="save-btn"
                          @click="saveChanges"
                        >
                          Save
                        </button>
                        <button
                          type="submit"
                          style="
                            background: white;
                            border: 1px solid #11365a;
                            color: #11365a;
                          "
                          class="save-btn"
                          @click="cancelChanges"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="separation-line"
          v-if="searchResults?.data?.length > 0"
        ></div>
        <div class="pagination" v-if="searchResults?.data?.length > 0">
          <div class="table-title2">
            {{ (currentPage - 1) * pageSize + 1 }}-
            {{ Math.min(currentPage * pageSize, searchResults?.totalCount) }}
            of {{ searchResults?.totalCount }} Orders / Page Size:
            <input
              type="number"
              class="page-size"
              autocomplete="off"
              v-model.number="pageSizeInput"
              @input="checkPageSizeChange"
            />
            <button
              type="button"
              v-if="pageSizeChanged"
              @click="applyPageSize"
              class="apply-btn"
            >
              Apply
            </button>
          </div>

          <vs-pagination
            :total-pages="searchResults?.noOfPages"
            :page-gap="mobile ? 1 : 2"
            :current-page="currentPage"
            @change="changePage"
          ></vs-pagination>
        </div>
      </div>
    </div>
    <div class="no-data-found" v-if="searchResults?.data?.length === 0">
      <NoData />
      <span>No data found</span>
    </div>
  </div>
  <Toastify ref="toastContainer" />
  <div class="loader-wrapper" v-if="isLoading">
    <LoaderAnimation />
  </div>
</template>
<script>
import NoData from "@/assets/Animation/NoData/NoData.vue";
import Toastify from "@/components/Toastify.vue";
import VsPagination from "@vuesimple/vs-pagination";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";
import {
  GetOrders,
  GetColumnList,
  UpdateOrder,
  GetMasterOrderDetails,
} from "@/api/APIs";
import FilterComponent from "@/components/FilterComponent.vue";
import DownloadPIFile from "@/components/DownloadPIFile.vue";
import ReportFileName from "@/components/ReportFileName.vue";
import { mapGetters } from "vuex";
import CopyButton from "@/components/CopyButton.vue";

export default {
  name: "ChinaOrders",
  components: {
    NoData,
    Toastify,
    VsPagination,
    LoaderAnimation,
    FilterComponent,
    DownloadPIFile,
    ReportFileName,
    CopyButton,
  },
  props: {
    property1: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      tabelColumns: [
        "Int. ID",
        "Order Date",
        "Due Date",
        "Company Name",
        "Order Status",
        "Customer Name",
        "Source",
      ],
      searchResults: [],
      searchQuery: "",
      selectedResult: null,
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      currentPage: 1,
      isLoading: false,
      selectedRow: null,
      refreshButtonVisible: false,
      pageSize: 20,
      pageSizeInput: 20,
      pageSizeChanged: false,
      showFilters: false,
      selectedCompanies: [],
      makerStatus: ["Working", "Done"],
      selectedRowInitialValues: {
        Order_status: null,
        Company_Id: null,
        Status_from_Handler: null,
        Trade_date_shared_status: null,
        Financial_Updated_Company: null,
        Financial_Updated_Parent: null,
        IMP_Remarks: null,
        Checker_name: null,
        Allocated_to: null,
        Reference_Order_Id: null,
        MD_Maker_DeliveryDate: null,
        Repeat_Urgent: null,
        otherAddresses: null,
        otherCompanyName: null,
        lineOfBusiness: null,
        riskScore: null,
      },
      changesMade: false,
      searchByColumn: [],
      byColumn: null,
      orderStatus: [],
      filters: [],
      selectedFilters: {},
      selectedFilterParams: "",
      tradeData: [],
      riskScoreList: [],
      orderDetails: null,
    };
  },
  watch: {
    combinedData: {
      handler() {
        if (this.selectedRow == null) return;
        const selectedResult = this.searchResults?.data[this.selectedRow];
        if (!selectedResult) return;
        this.changesMade =
          selectedResult.Order_status !==
            this.selectedRowInitialValues.Order_status ||
          selectedResult.Company_Id !==
            this.selectedRowInitialValues.Company_Id ||
          selectedResult.Status_from_Handler !==
            this.selectedRowInitialValues.Status_from_Handler ||
          selectedResult.Trade_date_shared_status !==
            this.selectedRowInitialValues.Trade_date_shared_status ||
          selectedResult.Financial_Updated_Company !==
            this.selectedRowInitialValues.Financial_Updated_Company ||
          selectedResult.Financial_Updated_Parent !==
            this.selectedRowInitialValues.Financial_Updated_Parent ||
          selectedResult.IMP_Remarks !==
            this.selectedRowInitialValues.IMP_Remarks ||
          selectedResult.Checker_name !==
            this.selectedRowInitialValues.Checker_name ||
          selectedResult.Reference_Order_Id !==
            this.selectedRowInitialValues.Reference_Order_Id ||
          selectedResult.MD_Maker_DeliveryDate !==
            this.selectedRowInitialValues.MD_Maker_DeliveryDate ||
          selectedResult.Repeat_Urgent !==
            this.selectedRowInitialValues.Repeat_Urgent ||
          selectedResult.Allocated_to !==
            this.selectedRowInitialValues.Allocated_to ||
          this.orderDetails.otherCompanyName !==
            this.selectedRowInitialValues.otherCompanyName ||
          this.orderDetails.lineOfBusiness !==
            this.selectedRowInitialValues.lineOfBusiness ||
          this.orderDetails.riskScore !==
            this.selectedRowInitialValues.riskScore ||
          JSON.stringify(this.orderDetails.otherAddresses) !==
            JSON.stringify(this.selectedRowInitialValues.otherAddresses);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getFiltersForScreen"]),

    savedFilters() {
      return this.getFiltersForScreen(this.$route.name);
    },
    combinedData() {
      return {
        searchResultsData: this.searchResults?.data,
        orderDetails: this.orderDetails,
      };
    },
    formattedSelectedDueDate: {
      get() {
        const selectedResult = this.searchResults?.data[this.selectedRow];
        if (!selectedResult || !selectedResult.MD_Maker_DeliveryDate) return "";
        return this.formattedDueDate(selectedResult.MD_Maker_DeliveryDate);
      },
      set(newValue) {
        const selectedResult = this.searchResults?.data[this.selectedRow];
        if (selectedResult) {
          selectedResult.MD_Maker_DeliveryDate =
            newValue === "" ? null : newValue;
        }
      },
    },
  },
  created() {
    this.pageSizeInput = this.pageSize;
    Promise.all([this.GetFilters()]);
  },
  mounted() {
    this.currentPage = 1;
    window.addEventListener("resize", this.handleResponsive);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResponsive);
  },

  methods: {
    cycleVerification(addressType) {
      const currentState =
        this.orderDetails.otherAddresses[addressType].isVerified;
      const nextState =
        currentState === "UNVERIFIED"
          ? "VALID"
          : currentState === "VALID"
          ? "INVALID"
          : "UNVERIFIED";
      this.orderDetails.otherAddresses[addressType].isVerified = nextState;
    },
    getToggleClass(state) {
      return state || "UNVERIFIED";
    },
    getToggleLabel(state) {
      if (state === "VALID") return "Verified";
      if (state === "INVALID") return "Not Verified";
      return "Pending Verification";
    },
    async GetDetails(orderId) {
      const payload = {
        internalOrderId: orderId,
      };
      try {
        let response = await GetMasterOrderDetails(payload);
        if (response) {
          this.orderDetails = response;
        } else {
          this.$refs.toastContainer.addToast("", { type: "error" });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
    },
    getOrderStatusStyle(status) {
      switch (status) {
        case "Request Received":
          return {
            background: "#FFE3E3",
            color: "#A64545",
          };
        case "Delivered":
          return {
            background: "#E3FFE3",
            color: "#45A645",
          };
        case "Clarification pending from bank":
          return {
            background: "#FFF3E3",
            color: "#A67A45",
          };
        case "With the checker":
          return {
            background: "#daeeff",
            color: "#025d93",
          };
        case "Final Check":
          return {
            background: "#ebdaff",
            color: "#54038c",
          };
        case "Ready_To_Share":
          return {
            background: "#c0d5ff",
            color: "#134cbb",
          };
        case "To_ First Level Check":
          return {
            background: "#ffd6fb",
            color: "#a61d97",
          };
        case "Cancelled":
          return {
            textDecoration: "line-through",
          };
        default:
          return {
            background: "transparent",
            color: "#11365A",
          };
      }
    },
    handleSearch(query, column) {
      (this.byColumn = column), (this.searchQuery = query);
      const newQuery = query.trim();
      this.currentPage=1
      if (newQuery.length === 0 || newQuery.length >= 1) {
        this.MyOrdersList();
      }
    },
    applyFilters(filterData) {
      this.selectedFilterParams = filterData;
      this.currentPage=1
      if (this.selectedFilterParams) {
        this.MyOrdersList();
      }
    },
    ClearFilters() {
      this.selectedFilters = { order: "DESC" };
      this.selectedFilterParams = `order=DESC&`;
      this.MyOrdersList();
    },
    formattedDueDate(date) {
      return date ? new Date(date).toISOString().split("T")[0] : null;
    },
    refreshData() {
      this.MyOrdersList();
    },
    handleKeyDown() {
      const query = this.searchQuery.trim();
      if (query.length === 0 || query.length >= 2) {
        this.MyOrdersList();
      }
    },
    async saveChanges() {
      const selectedResult = this.searchResults?.data[this.selectedRow];
      const payload = {
        internalOrderId: selectedResult?.Internal_order_id,
        page: "IND_CHN",
        updateObject: {},
      };
      Object.keys(this.selectedRowInitialValues).forEach((key) => {
        if (selectedResult[key] !== this.selectedRowInitialValues[key]) {
          payload.updateObject[key] = selectedResult[key];
        }
      });
      Object.keys(this.orderDetails).forEach((key) => {
        if (
          this.orderDetails[key] !== this.selectedRowInitialValues[key] &&
          Object.prototype.hasOwnProperty.call(
            this.selectedRowInitialValues,
            key
          )
        ) {
          payload.updateObject[key] = this.orderDetails[key];
        }
      });
      try {
        let response = await UpdateOrder(payload);
        if (response.message !== "FAILURE") {
          this.$refs.toastContainer.addToast(response.message, {
            type: "info",
          });
          this.MyOrdersList("save");
        } else {
          this.$refs.toastContainer.addToast(
            "Failed to save the changes. Please try again later.",
            { type: "error" }
          );
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status !== 403 &&
          error.response.status !== 409 &&
          error.response.status !== 400 &&
          error.response.status !== 201
        ) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }else if(error.response.status==409){
          window.alert(error.response.data.message);
          this.refreshData();
        }
      }
      this.changesMade = false;
    },
    cancelChanges() {
      const selectedResult = this.searchResults?.data[this.selectedRow];
      if (!selectedResult) return;

      selectedResult.Order_status = this.selectedRowInitialValues.Order_status;
      selectedResult.Company_Id = this.selectedRowInitialValues.Company_Id;
      selectedResult.Status_from_Handler =
        this.selectedRowInitialValues.Status_from_Handler;
      selectedResult.Trade_date_shared_status =
        this.selectedRowInitialValues.Trade_date_shared_status;
      selectedResult.Financial_Updated_Company =
        this.selectedRowInitialValues.Financial_Updated_Company;
      selectedResult.Financial_Updated_Parent =
        this.selectedRowInitialValues.Financial_Updated_Parent;
      selectedResult.IMP_Remarks = this.selectedRowInitialValues.IMP_Remarks;
      selectedResult.Checker_name = this.selectedRowInitialValues.Checker_name;
      selectedResult.Allocated_to = this.selectedRowInitialValues.Allocated_to;
      this.orderDetails.lineOfBusiness =
        this.selectedRowInitialValues.lineOfBusiness;
      this.orderDetails.riskScore = this.selectedRowInitialValues.riskScore;
      selectedResult.MD_Maker_DeliveryDate =
        this.selectedRowInitialValues.MD_Maker_DeliveryDate;
      selectedResult.Repeat_Urgent =
        this.selectedRowInitialValues.Repeat_Urgent;
      selectedResult.Reference_Order_Id =
        this.selectedRowInitialValues.Reference_Order_Id;
      this.orderDetails.otherCompanyName =
        this.selectedRowInitialValues.otherCompanyName;
      this.orderDetails.otherAddresses = JSON.parse(
        JSON.stringify(this.selectedRowInitialValues.otherAddresses)
      );
      this.changesMade = false;
    },
    checkPageSizeChange() {
      this.pageSizeChanged = this.pageSize !== this.pageSizeInput;
    },
    async applyPageSize() {
      this.pageSize = this.pageSizeInput;
      this.pageSizeChanged = false;
      await this.MyOrdersList();
    },
    async getUpdatedData(index) {
      try {
        let response = await GetOrders(
          this.searchResults.data[index].Internal_order_id,
          "",
          1,
          20,
          "Internal_order_id",
          true,
          "&Country=China"
        );
        if (response.data?.length === 0) {
          window.alert(
            "The data has been updated. Please refresh the list to see the latest information. Click OK to refresh."
          );
          this.refreshData();
          return;
        }
        const matchingEntry = response.data?.find(
          (entry) =>
            entry.Internal_order_id ===
            this.searchResults.data[index].Internal_order_id
        );
        if (matchingEntry) {
          this.searchResults.data[index] = {
            ...this.searchResults.data[index],
            ...matchingEntry,
          };
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async toggleRow(index) {
      if (this.changesMade) {
        this.cancelChanges();
      }
      if (this.selectedRow === index) {
        this.selectedRow = null;
      } else {
        this.getUpdatedData(index);
        await this.GetDetails(this.searchResults.data[index].Internal_order_id);
        this.selectedRow = index;

        const selectedResult = this.searchResults?.data[index];
        this.selectedRowInitialValues = {
          Order_status: selectedResult?.Order_status,
          Company_Id: selectedResult?.Company_Id,
          Status_from_Handler: selectedResult?.Status_from_Handler,
          Trade_date_shared_status: selectedResult?.Trade_date_shared_status,
          Financial_Updated_Company: selectedResult?.Financial_Updated_Company,
          Financial_Updated_Parent: selectedResult?.Financial_Updated_Parent,
          IMP_Remarks: selectedResult?.IMP_Remarks,
          Checker_name: selectedResult?.Checker_name,
          Allocated_to: selectedResult?.Allocated_to,
          Reference_Order_Id: selectedResult?.Reference_Order_Id,
          MD_Maker_DeliveryDate: selectedResult?.MD_Maker_DeliveryDate,
          Repeat_Urgent: selectedResult?.Repeat_Urgent,
          otherCompanyName: this.orderDetails.otherCompanyName,
          lineOfBusiness: this.orderDetails?.lineOfBusiness,
          riskScore: this.orderDetails?.riskScore,
          otherAddresses: JSON.parse(
            JSON.stringify(this.orderDetails.otherAddresses)
          ),
        };
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.MyOrdersList();
    },
    handleResponsive() {
      this.isMobile = window.innerWidth < 1023;
    },
    detectSafari() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
    },
    formatDate(dateString) {
      if (!dateString || dateString === undefined || dateString === "") {
        return null;
      }
      const date = new Date(dateString);
      const options = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };

      let formattedDate = date.toLocaleDateString("en-US", options);
      formattedDate;

      return formattedDate;
    },
    async MyOrdersList(save) {
      this.isLoading = true;
      this.selectedRow = null;
      this.selectedRowInitialValues = {
        Order_status: null,
        Company_Id: null,
        Status_from_Handler: null,
        Trade_date_shared_status: null,
        Financial_Updated_Company: null,
        Financial_Updated_Parent: null,
        IMP_Remarks: null,
        Checker_name: null,
        Allocated_to: null,
        Reference_Order_Id: null,
        MD_Maker_DeliveryDate: null,
        Repeat_Urgent: null,
        otherAddresses: null,
        otherCompanyName: null,
        lineOfBusiness: null,
        riskScore: null,
      };
      if (save !== "save") {
        this.searchResults = null;
      }
      try {
        let response = await GetOrders(
          this.searchQuery,
          this.selectedFilterParams,
          this.currentPage,
          this.pageSize,
          this.byColumn,
          true,
          "&Country=China"
        );
        this.searchResults = response;
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
      this.isLoading = false;
    },
    async GetFilters() {
      const payload = {
        page: "IND_CHN",
      };
      try {
        let response = await GetColumnList(payload);
        if (response.message === "SUCCESS") {
          this.searchByColumn = response.data;
          this.filters = response.filterList;
          this.orderStatus = response.orderStatusList;
          this.tradeData = response.tradeDataOptions;
          this.riskScoreList = response.riskScoreOptions;
          this.selectedFilters = { order: "DESC" };
          this.selectedFilterParams = `order=DESC&`;
          if (this.savedFilters && Object.keys(this.savedFilters).length == 0) {
            this.MyOrdersList();
          }
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          this.$refs.toastContainer.addToast(
            "Server Error, please try again later.",
            { type: "error" }
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.no-data-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16%;
  color: #11365a;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  gap: 2vh;
}
.logged-in {
  margin-left: 16vw;
  padding-top: 45px !important;
  transition: margin-left 0.3s ease;
}
.logged-in.collapsed {
  margin-left: 60px;
}
.not-logged-in {
  width: 100%;
}
.no-data-found span {
  margin-left: 7%;
  margin-top: 1rem;
  line-height: 300%;
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  opacity: 0.7;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
.tables-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 16px;
  background-color: white;
  position: relative;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 73vh;
  overflow-y: scroll;
  overflow-x: scroll;
  /* scrollbar-width: none;
    -ms-overflow-style: none; */
  gap: 15px;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: white;
  padding: 10px 16px;
}
#searchResultTable th:nth-child(2),
#searchResultTable th:nth-child(3) {
  min-width: 115px;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 13px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.separation-line {
  width: 100%;
  height: 2px;
  opacity: 0.2;
  background: #3e4855;
}
.frame-35732 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 15px;
  padding: 1.2rem 2rem 0rem 2rem;
}
.loader-wrapper {
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 8%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.table-title2 {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.expanded-row {
  background-color: #f9f9f9;
}
.expanded-content {
  padding: 10px;
  background-color: #f1f8ff;
}
.expanded-content p {
  margin: 5px 0;
}
select {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 8px;
  outline: none;
  height: 25px;
  color: #11365a;
  box-shadow: none;
}
.company-id {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 8px;
  outline: none;
  height: 25px;
  color: #11365a;
  box-shadow: none;
}
input[type="date"] {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 8px;
  outline: none;
  height: 25px;
  color: #11365a;
}
.filters-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  gap: 20px;
  align-items: stretch;
}
.search-wrapper {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.search-bar {
  padding: 8px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
  margin: 0;
  height: auto;
  width: 40vw;
  box-shadow: none;
}
.page-size {
  padding: 4px 5px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.16px;
  outline: none;
  width: 80px;
  margin-right: 10px;
}
.btn {
  background: #11365a;
  border: none;
  outline: none;
  height: auto;
  border-radius: 16px;
  padding: 0 5px;
  cursor: pointer;
}
.apply-btn {
  border: 1px solid #11365a;
  background: white;
  color: #11365a;
  outline: none;
  border-radius: 8px;
  width: auto;
}
.filter-btn {
  margin-left: 10px;
  background-color: #11365a;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.filter-menu {
  background: #f5f5f5;
  border: 1px solid #ccc;
  padding: 10px;
  position: absolute;
  z-index: 100;
  width: 300px;
}

.filter-group {
  margin-bottom: 10px;
}

.filter-group label {
  display: block;
  margin-bottom: 5px;
}
.icon-image {
  width: 35px;
  height: 35px;
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 13px;
}
.save-btn {
  background: #11365a;
  border: none;
  outline: none;
  height: 25px;
  border-radius: 8px;
  cursor: pointer;
}
.search-by-column {
  height: 100%;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  padding-left: 10px;
  cursor: pointer;
}
.maker-remark {
  border: 1px solid lightgray;
  border-radius: 8px;
  height: 30px;
  margin: 0;
  padding: 7px 20px;
  margin-left: 15px;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 13.33px;
  font-style: normal;
  box-shadow: none;
  outline: none;
}
.status-badge {
  border-radius: 13px;
  text-align: center;
  padding: 0 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-left: 10px;
  margin-top: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lightcoral;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #60cf60;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.three-way-toggle {
  width: 40px;
  height: 20px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.three-way-toggle::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.three-way-toggle.VALID {
  background-color: #60cf60;
}

.three-way-toggle.VALID::before {
  transform: translateX(20px);
}

.three-way-toggle.INVALID {
  background-color: lightcoral;
}

.three-way-toggle.INVALID::before {
  transform: translateX(0px);
}

.three-way-toggle.UNVERIFIED {
  background-color: lightgray;
}

.three-way-toggle.UNVERIFIED::before {
  transform: translateX(10px);
}

@media only screen and (max-width: 767px) {
  .table-container {
    padding-top: 1rem;
  }
  .tables-wrapper {
    padding: 1.2rem 1rem;
    border-top-right-radius: 0px;
  }
  .table-wrapper {
    gap: 1rem;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: 20px;
  }
  .result-wrapper {
    display: flex;
    padding: 21.634px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  .frame-35732 {
    padding: 0.5rem 0.8rem;
  }
  .table-wrapper {
    height: 67vh;
  }
  .pagination {
    justify-content: center;
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .table-container {
    padding-top: 0;
  }
  .table-wrapper {
    height: 73vh;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
<style>
.vs-pagination {
  /* padding: 0 !important; */
  gap: 5px;
  margin-top: 0;
}
.vs-pagination > li.vs-pagination--active a {
  color: white;
  background: #11365a;
}
.vs-pagination > li > a {
  border: 1px solid rgba(17, 54, 90, 0.5);
  border-radius: 5px;
  /* color: #11365A; */
}
</style>
