import axios from "axios";
import VueCookies from "vue-cookies";
import store from "@/store";

const baseURL = process.env.VUE_APP_API_HOST;

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (
        (error.response.status === 400 &&
          error.config.url.includes("/orders/update-order-internal")) ||
        (error.response.status === 201 &&
          error.config.url.includes("/orders/update-order-internal"))
      ) {
        alert(
          error.response.data.message ||
            "An error occurred while updating the order."
        );
      }
      if (error.response.status === 403) {
        store.dispatch("processLogout");
      }
    }
    return Promise.reject(error);
  }
);

export const Login = async (email, password) => {
  try {
    let payload = {
      email: email,
      password: password,
    };
    const response = await instance.post("/user/login", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const OTPVerification = async (email, token, otp) => {
  try {
    let payload = {
      email: email,
      secretValue: token,
      code: otp,
    };
    const response = await instance.post("/user/verify", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const Signup = async (name, email, password, enteredReferralCode) => {
  try {
    let payload = {
      name: name,
      email: email,
      password: password,
      referralCode: enteredReferralCode,
    };
    const response = await instance.post("/user/register", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const UserDetails = async (email, token) => {
  try {
    const authToken = JSON.stringify({ email: email, webToken: token });

    const response = await instance.post("/user/token", null, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching assets:", error);
    throw error;
  }
};

export const Logout = async (email, password) => {
  try {
    let payload = {
      email: email,
      password: password,
    };
    const response = await instance.post("/user/logout", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const CreateOrder = async (
  subjectName,
  subjectAddress,
  subjectCountry,
  orderEmail,
  phoneNo,
  fullLandline,
  reportType,
  repeatDetails,
  additionalAddress,
  name,
  referenceNumber,
  includeReferenceNumber,
  selectedCustomerType,
  currencyId,
  reportManual,
  piFileKey
) => {
  try {
    const authToken = JSON.stringify({
      email: store.state.userDetails.email,
      webToken: VueCookies.get("token"),
    });
    let payload = {
      subjectName: subjectName,
      subjectAddress: subjectAddress,
      subjectCountry: subjectCountry,
      orderEmail: orderEmail,
      orderContactNumber: phoneNo,
      orderLandlineNumber: fullLandline,
      reportType: reportType,
      reportDetails: repeatDetails,
      moreInformation: additionalAddress,
      customerName: name,
      referenceNumber: referenceNumber,
      includeReferenceNumber: includeReferenceNumber,
      customerType: selectedCustomerType,
      piFileKey: piFileKey,
      currencyId: currencyId,
      reportManual: reportManual,
    };
    const response = await instance.post("/orders/create-order", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AllCountries = async (products) => {
  try {
    const response = await instance.get(
      `/search/all-countries?noProducts=${products}`
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AllCurrencies = async () => {
  try {
    const response = await instance.get("/payment/all-currencies");
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const ProductVariant = async (payload) => {
  try {
    const response = await instance.post("/search/product-variant", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const FileUpload = async (payload) => {
  try {
    const response = await instance.post("/files/upload", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const PasswordAction = async (email, action, secretValue) => {
  try {
    let payload = {
      email: email,
      action: action,
      secret: secretValue !== "" ? secretValue : undefined,
    };
    const response = await instance.post(
      "/user/initiate-password-action",
      payload
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const CompletePasswordAction = async (email, token, otp, password) => {
  try {
    let payload = {
      email: email,
      secretValue: token,
      code: otp,
      password: password,
    };
    const response = await instance.post(
      "/user/complete-password-action",
      payload
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const UpdateUserDetails = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.put("/user/update", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const DownloadReportPDF = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/files/download-report", payload, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        "auth-token": authToken,
      },
    });
    return response;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const RepositoryOrders = async (page) => {
  const payload = {
    page: page,
  };
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/order-repository", payload, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const ActiveOrders = async (page) => {
  const payload = {
    page: page,
  };
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/active-orders", payload, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const CancelledOrders = async (page) => {
  const payload = {
    page: page,
  };
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/cancelled-orders", payload, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AddUserToEntity = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.put("/admin/link-user-entity", payload, {
      headers: {
        "auth-token": authToken,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AddSupervisor = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.put("/admin/add-supervisor", payload, {
      headers: {
        "auth-token": authToken,
        "Content-Type": "application/json",
        Cookie:
          "sails.sid=s%3AG-qR8Ao--WsLB5XOck_dMbYquAEu-A23.0koOoCklyLbpnVTDYFdG%2BlcRiZyxpiKKQt943F0gQD8",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const BulkUserUpload = async (payload, entity) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post(
      "/admin/bulk-user-upload?entity=" + entity,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": authToken,
        },
      }
    );
    return response && response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetProductConfig = async () => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.get("/admin/product-config", {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

// Bulk Order

export const AddToCart = async (
  subjectName,
  subjectAddress,
  subjectCountry,
  orderEmail,
  phoneNo,
  fullLandline,
  reportType,
  repeatDetails,
  additionalAddress,
  name,
  referenceNumber,
  includeReferenceNumber,
  selectedCustomerType,
  currencyId,
  reportManual,
  piFileKey
) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  let payload = {
    order: {
      subjectName: subjectName,
      subjectAddress: subjectAddress,
      subjectCountry: subjectCountry,
      orderEmail: orderEmail,
      orderContactNumber: phoneNo,
      orderLandlineNumber: fullLandline,
      reportType: reportType,
      reportDetails: repeatDetails,
      moreInformation: additionalAddress,
      customerName: name,
      referenceNumber: referenceNumber,
      includeReferenceNumber: includeReferenceNumber,
      customerType: selectedCustomerType,
      piFileKey: piFileKey,
      currencyId: currencyId,
      reportManual: reportManual,
    },
  };
  try {
    const response = await instance.post("/orders/add-to-cart", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const DeleteFromCart = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/delete-from-cart", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetCartItems = async () => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.get("/orders/get-cart-items", {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const CheckoutCart = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/checkout-cart", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const RequestReport = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/search/request-report", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AddBlog = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/blogs/add-blog", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const BlogBannerUpload = async (payload) => {
  try {
    const response = await instance.post("/files/blog-image-upload", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const BulkOrderUpload = async (payload, entity) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post(
      "/admin/bulk-order-upload?entity=" + entity,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": authToken,
        },
      }
    );
    return response && response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetOrders = async (
  searchquery,
  filters,
  page,
  pageSize,
  byColumn,
  IndiaAndChina,
  pageName
) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const query = byColumn && searchquery ? `${byColumn}=${searchquery}&` : "";
    const IndiaChina = IndiaAndChina ? `&indchn=${IndiaAndChina}` : "";

    const response = await instance.get(
      `/orders/get-orders-internal?${query}${filters}page=${page}&limit=${pageSize}${IndiaChina}${pageName}`,
      {
        headers: {
          "auth-token": authToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetMasterOrders = async (
  searchquery,
  filters,
  page,
  pageSize,
  byColumn
) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const query = byColumn && searchquery ? `${byColumn}=${searchquery}&` : "";
    const response = await instance.get(
      `/orders/get-new-orders?${query}${filters}page=${page}&limit=${pageSize}`,
      {
        headers: {
          "auth-token": authToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetColumnList = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post(
      "/common/get-product-config",
      payload,
      {
        headers: {
          "auth-token": authToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const UpdateOrder = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post(
      "/orders/update-order-internal",
      payload,
      {
        headers: {
          "auth-token": authToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetMasterOrderDetails = async (payload) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post(
      "/orders/get-master-order-details",
      payload,
      {
        headers: {
          "auth-token": authToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetUrgentOrders = async (
  searchquery,
  filters,
  page,
  pageSize,
  byColumn
) => {
  const authToken = JSON.stringify({
    email: store.state.userDetails.email,
    webToken: VueCookies.get("token"),
  });
  try {
    const query = byColumn ? `${byColumn}=${searchquery}&` : "";
    const response = await instance.get(
      `/orders/get-urgent-orders?${query}${filters}sortBy=created_at&page=${page}&limit=${pageSize}`,
      {
        headers: {
          "auth-token": authToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const ReportFileUpload = async (payload) => {
  try {
    const response = await instance.post("/files/upload-report", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};
